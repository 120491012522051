import {
  Box,
  Step,
  StepContent,
  StepLabel,
  Stepper,
  Typography,
  Button,
  useMediaQuery,
} from "@mui/material";
import { steps } from "../../constants/constant";
import { useState } from "react";

export default function About() {
  const isMobileMatch = useMediaQuery("(max-width:600px)");
  const [activeStep, setActiveStep] = useState(0);
  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };
  return (
    <div>
      <div className="bg-primary-subtle" style={{ backgroundColor: "#F6EACB" }}>
        <div className="container text-center pb-5">
          <h1 className="fs-2 fw-bold pt-5 pb-5">服务流程</h1>
          <div className="w-100 d-flex flex-column justify-content-center">
            <div className="w-100 d-flex justify-content-center">
              <Stepper
                activeStep={activeStep}
                orientation="vertical"
                className={isMobileMatch ? "w-75" : "w-25"}
              >
                {steps.map((step, index) => (
                  <Step key={step.label}>
                    <StepLabel
                      optional={
                        index === steps.length - 1 ? (
                          <Typography variant="caption">最后一步</Typography>
                        ) : null
                      }
                    >
                      {step.label}
                    </StepLabel>
                    <StepContent>
                      <Typography>{step.description}</Typography>
                      <Box sx={{ mb: 2 }}>
                        <Button
                          variant="contained"
                          onClick={handleNext}
                          sx={{ mt: 1, mr: 1 }}
                        >
                          {index === steps.length - 1 ? "完成" : "下一步"}
                        </Button>
                        <Button
                          disabled={index === 0}
                          onClick={handleBack}
                          sx={{ mt: 1, mr: 1 }}
                        >
                          上一步
                        </Button>
                      </Box>
                    </StepContent>
                  </Step>
                ))}
              </Stepper>
            </div>
            {activeStep === steps.length && (
              <div className="w-100 my-5">
                <Typography>您已完成所有流程</Typography>
                <Button onClick={handleReset} sx={{ mt: 1, mr: 1 }}>
                  重新查看流程
                </Button>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
