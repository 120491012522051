import { Grid } from "@mui/material";
import { workingIcon } from "../../assets/images/image";
import { ContactInfo } from "../../constants/contact";

export default function Contact() {
  return (
    <div style={{ backgroundColor: "#BEC6A0" }}>
      <div className="container text-center">
        <h2 className="mb-5 pt-5">联系我们</h2>
        <Grid container justifyContent={"center"}>
          <Grid item xs={12} md={12}>
            <div>
              <img src={workingIcon} alt="working" className="img-fluid"></img>
            </div>
          </Grid>
          <Grid
            item
            xs={12}
            md={6}
            className="my-5 d-flex flex-column justify-content-center"
          >
            <h3>地址</h3>
            <p>{ContactInfo.address}</p>
            <h3>联系电话</h3>
            {ContactInfo.telephone && <p>座机电话：{ContactInfo.telephone}</p>}
            {ContactInfo.cellphone.map((a) => (
              <p>移动电话：{a}</p>
            ))}
            <h3>邮箱</h3>
            <p>{ContactInfo.email}</p>
          </Grid>
        </Grid>
      </div>
    </div>
  );
}
