import { Grid } from "@mui/material";
import { ContactInfo } from "../../constants/contact";
import { ICP } from "../../constants/constant";
import { logoIcon } from "../../assets/images/image";

export const Footer = () => {
  return (
    <div className="text-center py-5" style={{ backgroundColor: "#F6EACB" }}>
      <div className="w-100 h-1 border mt-5"></div>
      <Grid container justifyContent={"center"}>
        <Grid item md={3} margin={2}>
          <p className="fs-5 fw-bold">地址</p>
          <p>{ContactInfo.address}</p>
        </Grid>
        <Grid item md={3} margin={2}>
          <p className="fs-5 fw-bold">联系我们</p>
          <p className="m-0">邮箱：{ContactInfo.email}</p>
          {ContactInfo.telephone && (
            <p className="m-0">座机电话：{ContactInfo.telephone}</p>
          )}
          {ContactInfo.cellphone.map((a) => (
            <p className="m-0">移动电话：{a}</p>
          ))}
        </Grid>
        <Grid item md={3} margin={2}>
          <p className="fs-5 fw-bold">关于我们</p>
          <p>晶采文浩科技是一家专业的数据库建造和软件开发公司</p>
        </Grid>
      </Grid>
      <div className="w-100 h-1 border mb-5"></div>
      <Grid container justifyContent={"center"} flexDirection={"column"}>
        <div>晶采文浩科技 ©</div>
        <div className="my-3">
          <img
            style={{ width: "50px", height: "50px" }}
            src={logoIcon}
            alt="logo"
          ></img>
        </div>
        <div>
          <a href="https://beian.miit.gov.cn/" rel="noreferrer" target="_blank">
            {ICP}
          </a>
        </div>
      </Grid>
    </div>
  );
};
