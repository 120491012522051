import computerScreen from "./computer-screen.svg";
import intranet from "./intranet-technical.svg";
import logo from "./logo.png";
import logo_text from "./logo_text.svg";
import company from "./company.jpg";
import wave from "./wave.svg";
import working from "./working.jpg";
import database1 from "./database1.jpg";
import database2 from "./database2.jpg";
import notFound from "./error.png";
import softwareDevelopment from "./sd.jpg";
import db_instalation from "./db_installation.webp";

export const computerScreenIcon = computerScreen;
export const intranetIcon = intranet;
export const logoIcon = logo;
export const logoTextIcon = logo_text;
export const companyIcon = company;
export const waveIcon = wave;
export const workingIcon = working;
export const database1Icon = database1;
export const database2Icon = database2;
export const notFoundIcon = notFound;
export const softwareDevelopmentIcon = softwareDevelopment;
export const dbInstallationIcon = db_instalation;
