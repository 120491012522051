import "bootstrap/dist/css/bootstrap.min.css";
import { Grid } from "@mui/material";
import Lottie from "lottie-react";
import databaseAnimation from "../../assets/database.json";
import { workingIcon } from "../../assets/images/image";

function Home() {
  return (
    <div>
      <div style={{ backgroundColor: "#CDE8E5" }}>
        <div className="container">
          <Grid container paddingY={10} justifyContent={"center"}>
            <Grid
              item
              xs={12}
              columnSpacing={2}
              className="container d-flex flex-column justify-content-center text-center"
            >
              <h1>晶采文浩科技</h1>
              <h5 className="mt-2">
                {`作为一家专注于互联网数据服务的科技公司,晶采文浩科技致力于为各行业提供高效
             、安全、精准的数据解决方案。我们通过大数据分析、云计算、数据存储等先进技术，
             帮助企业深度挖掘数据价值，推动数字化转型。无论是数据采集、清洗、分析，还是数据可视化呈现，
             我们都提供一站式的综合服务，助力客户在竞争激烈的市场中实现卓越增长。
`}
              </h5>
            </Grid>
            <Grid item>
              <div className="container">
                <img alt="home" src={workingIcon} className="img-fluid"></img>
              </div>
            </Grid>
          </Grid>
          <Grid container spacing={2} justifyContent={"center"}>
            <Grid
              item
              xs={12}
              columnSpacing={2}
              className="container d-flex flex-column justify-content-center text-center"
            >
              <h1>愿景</h1>
              <h5 className="mt-2">
                {`我们致力于成为领先的数据驱动解决方案提供商，通过技术创新与数据赋能，
              帮助企业实现智能决策、提升运营效率。未来，我们将继续为客户创造更多商业价值，
              推动各行业的数字化转型，构建一个更加智能和互联的世界。
            `}
              </h5>
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <div className="container">
                <Lottie
                  animationData={databaseAnimation}
                  className="w-100 h-100"
                ></Lottie>
              </div>
            </Grid>
          </Grid>
        </div>
      </div>
    </div>
  );
}
export default Home;
