export const ICP = "沪ICP备2024092688号";
export const steps = [
  {
    label: "需求沟通",
    description: `我们的项目团队将与客户进行详细沟通，
    深入了解客户的业务需求和数据目标。通过初步的需求分析，我们会为客户制定定制化的数据服务方案。`,
  },
  {
    label: "方案设计",
    description: `根据客户的具体需求，我们的技术团队将制定详细的实施方案，涵盖数据采集、处理、分析和可视化的各个环节。方案会充分考虑客户的业务目标、技术环境和预算。`,
  },
  {
    label: "数据采集",
    description: `我们使用先进的数据采集工具和技术，从多源（如物联网设备、业务系统、公开数据等）进行数据采集，确保数据的准确性和时效性。我们也会根据行业规范，确保数据采集的合法性和合规性。`,
  },
  {
    label: "数据清洗与处理",
    description: `采集到的数据经过严格的清洗、去重和标准化处理，确保数据的完整性和一致性。我们会使用智能算法自动清洗数据，去除无效信息并解决数据冲突。`,
  },
  {
    label: "数据分析与建模",
    description: `数据处理完成后，我们的专业数据分析团队会运用先进的分析工具和机器学习算法，对数据进行深度挖掘和建模，生成对业务决策有价值的洞察。`,
  },
  {
    label: "数据可视化",
    description: `分析结果将通过可视化仪表板呈现，帮助客户快速理解复杂的业务数据。我们提供多种图表和报告形式，支持定制化的交互式数据展示，方便客户根据需求查看和操作数据`,
  },
  {
    label: "交付与培训",
    description: `在项目完成后，我们会向客户交付所有数据报告、可视化工具以及分析结果。为确保客户能够充分利用数据成果，我们将提供全面的培训和支持，确保系统顺利上线并融入业务流程。`,
  },
  {
    label: "持续支持与优化",
    description: `项目交付后，我们将继续提供技术支持和维护服务，确保数据服务的高效运行。根据客户反馈和业务发展需求，我们也会定期对系统进行优化和升级，以帮助客户获得持续的商业价值。`,
  },
];
